/* eslint-disable */

;(function(root, factory) {

    if (typeof define === 'function' && define.amd) {
      define(factory);
    } else if (typeof exports === 'object') {
      module.exports = factory();
    } else {
      root.Metatags = factory();
    }
  
  })(this, function() {
    var Metatags = {};

    Metatags.version = '0.0.1';

    /**
     * Updates metatags
     */
    Metatags.update = function(title, description, noIndex = false) {
      document.title = title;
      document.head.querySelector('meta[name="description"]').setAttribute('content', description);

      const robot = document.head.querySelector('meta[name="robots"]');
      if (robot !== null) {
          robot.remove();
      }
      if (noIndex) {
          let meta = document.createElement('meta');
          meta.name = 'robots';
          meta.content = 'noindex';
          document.head.appendChild(meta);
      }

      return this;
    };

  
    return Metatags;
  });
  