<template>
  <div class="home-slider-component">
    <div class="central-container">
      <div v-for="item in sliders" :key="item.index" class="slide">
        <div class="item-info-container">
          <div class="item-info">
            <h2 v-html="item.name" class="main-title-blue" />
            <p v-if="item.description" class="description" v-html="item.description"></p>
            <div class="cta" v-if="item.cta_link">
                <a :href="item.cta_link">{{item.cta_title}}</a>
            </div>
          </div>
        </div>
        <div class="background-image">
          <img v-lazy="item.image" v-bind:alt="item.name" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BACKEND_API } from "../../config/api";
import { API_ROUTE } from "../../config/api";

export default {
  name: "HomeSlider",
  data: () => {
    return {
      errorMessage: "",
      sliders: "",
      isSliderReady: false
    };
  },
  mounted() {
    BACKEND_API.get(API_ROUTE.HOME_SLIDER)
      .then(response => {
        this.sliders = response.data;
        this.isSliderReady = true;
      })
      .catch(e => {
        this.errorMessage = e;
      });
  }
};
</script>
<style lang="scss">
  .home-slider-component {
    .main-title-blue {
      color:#54b7bc;
      font-size: 2rem;
      font-weight: 400;
    }
    .central-container {
      .slide {
        display: flex;
        align-items: flex-end;
        height: 656px;

        .item-info-container {
          width: 350px;
          position: relative;

          .item-info {
            background: #101416;
            width: 270px;
            bottom: 40px;
            padding: 35px 30px;
            text-align: left;
            left: 140px;
            margin-left: 140px;
            margin-bottom: 40px;

            .description {
              color: #ffffff;
              font-size: 0.9rem;
            }
          }
        }
        .background-image {
          width: calc(100% - 350px);
          height: 100%;

          img {
            display: block;
            height: 656px;
            object-fit: cover;
            width: 100%;
          }
        }
      }
    }
  }

  .agile {
    &__actions {
      width: 1365px;
      margin: 0 auto;
    }
    .item-info {
      width: 1365px;
      margin: 0 auto;
    }
    &__nav-button {
      background: transparent;
      border: none;
      color: #ccc;
      cursor: pointer;
      font-size: 24px;
      transition-duration: 0.3s;

      &:hover {
        color: #888;
      }
    }
    &__dot {
      margin: 0 10px;

      button {
        background-color: #eee;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        display: block;
        height: 10px;
        font-size: 0;
        line-height: 0;
        margin: 0;
        padding: 0;
        transition-duration: 0.3s;
        width: 10px;
      }
      &--current,
      &:hover {
        button {
          background-color: #888;
        }
      }
    }
  }
  @media screen and (max-width: 1365px) {
    .home-slider-component {
      .central-container {
        .slide {
          position: relative;

          .item-info-container {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;

            .item-info {
              margin: unset;
              width: auto;
              bottom: unset;
              left: unset;
              background-color: #101416ba;

              h3 {
                margin-top: unset;
              }
            }
          }
          .background-image {
            width: 100%;
          }
        }
      }
    }
  }
  @media screen and (max-width: 970px) {
    .home-slider-component {
      .central-container {
        .slide {
          position: relative;
          height: 456px;

          .background-image {
            img {
              height: 456px;
            }
          }
        }
      }
    }
  }
</style>