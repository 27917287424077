<template>
  <div class="home">
    <h1>Agence Flaxeo : Création de site e-commerce Magento, développements web</h1>
    <HomeSlider />
    <Verticalsep />
    <HomeStaticBlock />
    <Verticalsep />
    <HomeTechnos />
    <HomeReference />
  </div>
</template>

<script>
import HomeSlider from "@/components/Home/HomeSlider.vue";
import HomeStaticBlock from "@/components/Home/HomeStaticBlock.vue";
import HomeTechnos from "@/components/Home/HomeTechnos.vue";
import HomeReference from "@/components/Home/HomeReference.vue";
import Verticalsep from "@/components/Verticalsep.vue";
import Metatags from "@/script/metatags.js";

export default {
  name: "Home",
  components: {
    HomeSlider,
    HomeStaticBlock,
    HomeTechnos,
    HomeReference,
    Verticalsep
  },
  mounted() {
    window.scrollTo(0, 0);
    Metatags.update(
        'Flaxeo, conception et hébergement de site web',
        'Flaxeo vous accompagne dans vos projets, de la solution d’hébergement web à la conception de site web.'
    );
  }

};
</script>

<style lang="scss" scoped>
h1 {
  position: absolute;
  left: 0;
  width: 110px;
  height: 70px;
  font-size: 0;
  color: #f00;
}
</style>