<template>
  <div class="vertical-separator">
  </div>
</template>

<script>
export default {
  name: "Verticalsep",
  data: () => {
    return {
      isMenuDisplayed: false
    };
  },
  methods: {
   
  }
};
</script>

<style lang="scss">
  @import "@/styles/_variables.scss";

  .vertical-separator {
    height: 214px;
    width: 200px;
    margin: 0 auto;
    background-color: white;
    position: relative;

    &:after {
      content: " ";
      display: block;
      background: #101416;
      width: 4px;
      height: 64px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

    @media screen and (max-width: 640px) {
      .vertical-separator {
        height: 174px;
      }
    }
</style>