<template>
  <div class="nos-references-component">
    <div class="central-container">
      <div class="references-description">
        <h3>Les marques qui nous font confiance !</h3>
      </div>
      <div class="short-desc">
        Nos clients grands-comptes nous ont confié leurs projets pour la conception de site ainsi que pour des conseils stratégiques.
      </div>
      <ul class="ref-list">
        <li v-for="item in references" :key="item.index">
          <router-link :to="item.url">
            <img :alt="item.name" v-lazy="item.image" />
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { BACKEND_API } from "../../config/api";
import { API_ROUTE } from "../../config/api";

export default {
  name: "HomeSlider",
  data: () => {
    return {
      errorMessage: "",
      references: "",
      isReferenncesReady: false
    };
  },
  mounted() {
    BACKEND_API.get(API_ROUTE.REFERENCES)
      .then(response => {
        this.references = response.data;
        this.isReferenncesReady = true;
      })
      .catch(e => {
        this.errorMessage = e;
      });
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/_variables.scss";

  .nos-references-component {
    margin: 0 auto;
    max-width: 1081px;
    padding: 0 20px;
    text-align: left;

    .central-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .references-description {
        padding: 45px 0 0;

        h3 {
          color: $main-text-color;
          font-size: 1.9rem;
          background-color: white;
        }
      }
      .short-desc {
        padding: 20px 0;
        background-color: white;
      }
      .ref-list {
        list-style: none;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 20px;
        background-color: white;
        width: calc(100% - 40px);

        li {
          width: calc(33% - 30px);
          min-height: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          padding: 10px 0;

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            max-height: 80px;
            max-width: 300px;

            img {
              max-height: 80px;
              width: 100%;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 740px) {
    .nos-references-component {
      .central-container {
        .ref-list {
          li {
            width: calc(50% - 30px);
          }
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    .nos-references-component {
      .central-container {
        align-items: center;
      }
    }
  }
@media screen and (max-width: 450px) {
  .nos-references-component {
    .central-container {
      .ref-list {
        li {
          width: calc(100% - 30px);
        }
      }
    }
  }
}
</style>