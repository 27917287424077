<template>
  <div class="home-static-block-component">
    <div class="central-container">
      <div class="title">
        <h3 class="black">Nos services</h3>
      </div>
      <div v-for="block in blocks" :key="block.index" class="image-position" :class="block.image_position">
        <div class="left-container container">
          <span class="image-container" v-if="block.image_position === 'left'">
            <img v-lazy="block.image" />
          </span>
          <div v-if="block.image_position === 'right'" class="container-description">
            <span class="text">
              <h3>{{block.name}}</h3>
              <span class="description" v-html="block.description"></span>
            </span>
            <button class="action">
              <router-link to="expertise/">Découvrir</router-link>
            </button>
          </div>
        </div>
        <div class="right-container container">
          <span  class="image-container" v-if="block.image_position === 'right'">
            <img v-lazy="block.image" />
          </span>
          <div v-if="block.image_position === 'left'" class="container-description">
            <span class="text">
              <h3>{{block.name}}</h3>
              <span class="description" v-html="block.description"></span>
            </span>
            <button class="action">
              <router-link to="expertise/">Découvrir</router-link>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BACKEND_API } from "../../config/api";
import { API_ROUTE } from "../../config/api";

export default {
  name: "HomeSlider",
  data: () => {
    return {
      errorMessage: "",
      blocks: ""
    };
  },
  mounted() {
    BACKEND_API.get(API_ROUTE.HOME_STATICBLOCK)
      .then(response => {
        this.blocks = response.data;
      })
      .catch(e => {
        this.errorMessage = e;
      });
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/_variables.scss";

  .home-static-block-component {
    &:after {
      content: " ";
      background: url("../../assets/x.png") right no-repeat;
      background-size: 430px;
      background-position-x: -20px;
      width: 290px;
      height: 388px;
      margin-top: -380px;
      position: absolute;
      right: 0;
      display: block;
    }
    .central-container {
      .title {
        max-width: 350px;
        margin: 0 auto;

        &::before {
          content: " ";
          background: url("../../assets/x.png") right no-repeat;
          background-size: 280px;
          width: 240px;
          height: 260px;
          margin-top: -80px;
          position: absolute;
          left: 0;
          display: block;
        }

        h3 {
          margin: unset;
          padding: 1em;
          background: white;
        }
      }

      .image-position {
        display: flex;
        align-items: stretch;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 780px;
        width: auto;
        margin: 0 auto;
        text-align: left;
        padding: 40px 0;

        .container {
          width: 50%;
          padding: 25px;
          box-sizing: border-box;

          .container-description {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            height: 100%;

            .text {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              h3 {
                margin: 0 0 15px;
                background-color: white;
              }
              .description {
                display: block;
                color: $second-text-color;
                font-size: 14px;
                background-color: white;
                padding: 1px 0;
              }
            }
            .action {
              a {
                color: white;
                text-decoration: none;
              }
            }
          }
          .image-container {
            display: block;
            height: 100%;

            img {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1365px) {
    .home-static-block-component {
      &:after {
        width: calc(50% - 390px);
        left: 50%;
        right: unset;
        margin-left: 390px;
      }
      .central-container {
        .title {
          &::before {
            right: 50%;
            left: unset;
            margin-right: 443px;
            width: calc(50% - 443px);
          }
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    .home-static-block-component {
      &:after {
        display: none;
      }
      .central-container {
        .title {
          &::before {
            display: none;
          }
        }
        .image-position {
          padding: 20px 0;

          &.right .right-container,
          &.left .left-container {
            width: 270px;
            order: 1;
            padding-bottom: unset;
          }
          &.left .right-container,
          &.right .left-container {
            width: 100%;
            order: 2;
            padding-top: 10px;

            .container-description {
              .text {
                h3 {
                  padding-bottom: unset;
                }
              }
            }
          }
        }
      }
    }
  }
</style>